/* eslint-disable */
import React, { ReactNode, useEffect, useState } from "react";

interface FeatureScrollContainerProps {
  direction: "ltr" | "rtl";
  children: ReactNode;
  imageSrc: string;
  topPosition?: string; // Optional prop with default value
}

const FeatureScrollContainer: React.FC<FeatureScrollContainerProps> = ({
  direction,
  children,
  imageSrc,
  topPosition = "20%", // Default value
}) => {
  const isLTR = direction === "ltr";

  return (
    <div className="w-full">
      <div className="lg:hidden flex flex-col gap-y-10">
        <img
          src={imageSrc}
          alt="Scrolling"
          className={`w-full max-w-[300px] mx-auto mb-4 ${isLTR ? "order-1" : "order-2"}`}
        />
        <div className={isLTR ? "order-2" : "order-1"}>{children}</div>
      </div>
      <div className="hidden lg:grid lg:grid-cols-2 h-fit w-full justify-center items-start relative">
        <div
          className="sticky flex justify-center items-center"
          style={{ top: topPosition }}
        >
          {children}
        </div>
        <div className={`flex items-center justify-center h-fit ${isLTR ? "" : "row-start-1"}`}>
          <img
            src={imageSrc}
            alt="Scrolling"
            className="w-full max-w-[300px]"
          />
        </div>
      </div>
    </div>
  );
};

export const FeatureScroll1: React.FC = () => {
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr');
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) { // Adjust this breakpoint as needed
        setDirection('rtl');
      } else {
        setDirection('ltr');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col gap-20 container p-10">
        <FeatureScrollContainer
          topPosition="20%"
          direction="rtl"
          imageSrc="assets/images/home/easilySell/anyfencephone.png"
        >
          <div className="flex flex-col gap-4 max-w-sm mx-auto lg:mx-0 items-center justify-center lg:items-start lg:justify-start text-center lg:text-left">
            <h1 className="text-4xl font-bold">Easily sell any fence. 
            </h1>
            <p className="text-lg">
              Literally every fence you sell, or any fence you could possibly imagine...
            </p>
          </div>
        </FeatureScrollContainer>

        <FeatureScrollContainer
          topPosition="20%"
          direction={direction}
          imageSrc="assets/images/home/easilySell/chainlinkphone.png"
        >
          <div className="flex flex-col gap-4 max-w-sm mx-auto lg:mx-0 items-center justify-center lg:items-start lg:justify-start text-center lg:text-left">
            <h1 className="text-4xl font-bold">Boom, it's right in your customer’s yard.</h1>
            <p className="text-lg">
              Within seconds, your customer is viewing a live fence, right in their own yard. Their reaction is priceless every time...
            </p>
            <div className="flex gap-4">
              <a href="/subscription" className="bg-neutral-800 text-white px-4 py-2 rounded-md">
                Get Started
              </a>
            </div>
          </div>
        </FeatureScrollContainer>
      </div>
    </section>
  );
};

export default FeatureScroll1;
