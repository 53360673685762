
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { m, useInView, useSpring, useTransform } from "framer-motion";

interface Stat {
  value: string | number;
  label: string;
  suffix?: string; // Assuming suffix is optional
}

const stats: Stat[] = [
  { value: "Millions", label: "Dollars of fences sold" },
  { value: "~25%", label: "Sales boost for hundreds of sales reps" },
  { value: 50, label: "Sold a Fence in all 50 States" },
  { value: "Thousands", label: "Total Downloads of Realityfence" },
];

interface TypeAnimationProps {
  text: string;
}

function TypeAnimation({ text }: TypeAnimationProps) {
  const [displayText, setDisplayText] = useState<string>('');
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText(prev => prev + text[index]);
        setIndex(index + 1);
      }, 100); // Adjust the speed of typing here

      return () => clearTimeout(timeout);
    }
  }, [index, text]);

  return <span>{displayText}</span>;
}

export function Stats() {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <section id="stats" ref={ref}>
      <div className="container px-4 md:px-6 py-8 md:py-16"> {/* Reduced top padding */}
        <div className="text-center space-y-4 py-4 mx-auto"> {/* Reduced padding */}
          <h4 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2 text-balance max-w-3xl mx-auto tracking-tighter">
            We've reached unbelievable milestones,<br />but we're only getting started.
          </h4>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
          {stats.map((stat, index) => (
            <AnimatedCard
              key={index}
              value={stat.value}
              label={stat.label}
              suffix={stat.suffix}
              isInView={isInView}
              useTextAnimation={index === 0 || index === 1 || index === 3}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

interface AnimatedCardProps {
  value: string | number;
  label: string;
  suffix?: string; // Assuming suffix is optional
  isInView: boolean;
  useTextAnimation: boolean;
}

function AnimatedCard({ value, label, suffix, isInView, useTextAnimation }: AnimatedCardProps) {
  const spring = useSpring(0, { duration: 2000 });
  const displayValue = useTransform(spring, (current) =>
    typeof value === 'number' ? Math.floor(current).toLocaleString() : value
  );

  useEffect(() => {
    if (isInView && typeof value === 'number') {
      spring.set(value);
    }
  }, [isInView, spring, value]);

  return (
    <div className="border-none shadow-none">
      <div className="p-4 text-center">
        <m.div
          className="text-4xl md:text-5xl font-bold mb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          {useTextAnimation ? (
            <TypeAnimation text={String(value)} /> // Ensure value is a string
          ) : (
            <m.span>{displayValue}</m.span>
          )}
          {suffix && suffix}
        </m.div>
        <div className="text-sm md:text-base text-muted-foreground">
          {label}
        </div>
      </div>
    </div>
  );
}

export default Stats;
