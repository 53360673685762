/* eslint-disable */
import { AnimatePresence, m } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack } from "@mui/material";
import { HEADER } from "../../../config-global";
import useResponsive from "../../../hooks/useResponsive";
import useOffSetTop from "../../../hooks/useOffSetTop";
import { navConfig } from "../../../layouts/home/nav/config-navigation";
import { PATH_AUTH, PATH_HOME } from "../../../routes/paths";
import NavMobile from "../../../layouts/home/nav/mobile";
import NavDesktop from "../../../layouts/home/nav/desktop";
import './index.css'
export function useScrollY(containerRef: React.RefObject<HTMLElement>) {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setScrollY(containerRef.current.scrollTop);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef]);

  return scrollY;
}

export function StickyHeader({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLElement>;
}) {
  const scrollY = useScrollY(containerRef);
  const stickyNavRef = useRef<HTMLElement>(null);
  const theme = useTheme();

  const isDesktop = useResponsive('up', '900');
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <header ref={stickyNavRef} className="sticky top-0 z-50 px-8 sm:px-10 py-5 sm:py-6  xl:px-4">
      <nav className="relative mx-auto flex items-center justify-between">
        <a href="/">
          <m.img
            className="h-14 md:h-12 w-35"
            src={
              isDesktop
                ? '/assets/images/home/RF-Logo-Blue.svg'
                : '/assets/images/home/RF Logo Blue.png'
            }
            alt="MagicUI Logo"
            animate={{
              y: scrollY >= 120 ? -50 : 0,
              opacity: scrollY >= 120 ? 0 : 1,
            }}
            transition={{ duration: 0.15 }}
          />
        </a>

        {isDesktop && (
          <ul
            className="sticky left-4 right-4 top-4 z-[60]  items-center justify-center gap-x-5"
            style={{ marginRight: '-90px' }}
          >
            <m.div
              initial={{ x: 0 }}
              animate={{
                boxShadow:
                  scrollY >= 120
                    ? theme.palette.primary.dark === 'dark'
                      ? '0 0 0 1px rgba(255,255,255,.08), 0 1px 2px -1px rgba(255,255,255,.08), 0 2px 4px rgba(255,255,255,.04)'
                      : '0 0 0 1px rgba(17,24,28,.08), 0 1px 2px -1px rgba(17,24,28,.08), 0 2px 4px rgba(17,24,28,.04)'
                    : 'none',
              }}
              transition={{
                ease: 'linear',
                duration: 0.05,
                delay: 0.05,
              }}
              className="flex w-auto items-center justify-center overflow-hidden rounded-full transition-all bg-background gap-x-[48px] lg:gap-x-[32px]"
              style={{ background: '#FFF', padding: '8px 24px' }}
            >
              <nav className="relative h-full items-center justify-between gap-x-3.5 md:flex">
                <ul className="flex h-full flex-col justify-center gap-6 md:flex-row md:justify-start md:gap-0 lg:gap-1">
                  <NavDesktop data={navConfig} />
                </ul>
              </nav>
              <m.div
                initial={{ width: 0 }}
                animate={{
                  width: scrollY >= 120 ? 'auto' : 0,
                }}
                transition={{
                  ease: 'linear',
                  duration: 0.25,
                  delay: 0.05,
                }}
                className="block-custom overflow-hidden rounded-full hidden-custom"
              >
                <AnimatePresence>
                  {scrollY >= 120 && (
                    <m.ul
                      initial={{ x: '125%' }}
                      animate={{ x: '0' }}
                      exit={{
                        x: '125%',
                        transition: { ease: 'linear', duration: 1 },
                      }}
                      transition={{ ease: 'linear', duration: 0.3 }}
                      className="shrink-0 whitespace-nowrap"
                    >
                      <li>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={{ md: 4, lg: 4, xl: 6 }}
                        >
                          {true && (
                            <Button
                              data-nav-config={navConfig}
                              variant="text"
                              color="inherit"
                              style={{
                                color: '#1FA9FF',
                                fontSize: 18,
                                borderRadius: 999,
                                padding: '0px',
                              }}
                              href={PATH_AUTH.login}
                            >
                              Login
                            </Button>
                          )}

                          {true && (
                            <Button
                              variant="text"
                              color="inherit"
                              style={{
                                color: 'white',
                                backgroundColor: '#1FA9FF',
                                padding: '8px 16px',
                                fontSize: 18,
                                borderRadius: 999,
                                width: '170px',
                              }}
                              href={PATH_HOME.subscription}
                              data-nav-config={JSON.stringify(navConfig)}
                            >
                              Buy Now
                            </Button>
                          )}
                        </Stack>
                      </li>
                    </m.ul>
                  )}
                </AnimatePresence>
              </m.div>
            </m.div>
          </ul>
        )}
        {isDesktop && (
          <m.div
            className="z-[999] items-center gap-x-5 "
            animate={{
              y: scrollY >= 120 ? -50 : 0,
              opacity: scrollY >= 120 ? 0 : 1,
            }}
            transition={{ duration: 0.15 }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: 210,
              }}
            >
              <Button
                data-nav-config={navConfig}
                variant="text"
                color="inherit"
                style={{
                  color: '#1FA9FF',
                  padding: '8px 16px',
                  fontSize: 18,
                  borderRadius: 999,
                }}
                href={PATH_AUTH.login}
              >
                Login
              </Button>

              <Box sx={{ flexGrow: 0.1 }} />
              <Button
                variant="text"
                color="inherit"
                style={{
                  color: 'white',
                  backgroundColor: '#1FA9FF',
                  padding: '8px 16px',
                  fontSize: 18,
                  borderRadius: 999,
                }}
                href={PATH_HOME.subscription}
                data-nav-config={JSON.stringify(navConfig)} // Optional way to pass data
              >
                Buy Now
              </Button>
            </Stack>
          </m.div>
        )}
        {!isDesktop && (
          <NavMobile
            isOffset={isOffset ? true : undefined}
            data={navConfig}
            isDesktop={isDesktop}
          />
        )}
      </nav>
    </header>
  );
}
