import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { UserRoles, VERIFY_TYPE } from '../utils/constants';
import {
  // MESSAGE TYPE
  ERROR,
  SUCCESS as SUCCESS_MESSAGE,

  // ERROR MESSAGES
  USER_NOT_EXITS,
  PASSWORD_INCORRECT,
  USER_IS_BANNED as USER_IS_BANNED_MESSAGE,
  EMAIL_PASSWORD_REQUIRED,
  USER_NOT_VERIFIED as USER_NOT_VERIFIED_MESSAGE,
  VALIDATION_ERROR as VALIDATION_ERROR_MESSAGE,
  EMAIL_ALREADY_EXIST,
  CONFIRM_PAYMENT_USER_EXIST,

  // SUCCESS MESSAGES
  USER_REGISTER,
  PASSWORD_CHANGED,
  RESET_PASSWORD_SUCCESS,
  EMAIL_NO_EXIST,
  CONFIRM_PASSWORD_INCORRECT,
  CODE_INCORRECT,
  VERIFICATION_SENT,
  VERIFICATION_RESENT,
} from '../utils/messages';

import { PATH_AUTH, PATH_DASHBOARD, PATH_HOME, PATH_ONBOARDING } from '../routes/paths';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'RELOADME') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const { id } = jwtDecode(accessToken);
        const response = await axios.get(`/auth/initialize/${id}`);
        if (response.data.code === 200) {
          const user = response.data.data;
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          setSession(null);
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (loginData, navigate, snackbar) => {
    let response={};
    let resData = {};
    try{
      response = await axios.post('/auth/signin', loginData);
      resData = response.data.data;
    }catch(err){
       console.log("Error : ",err);
       response.data = err;
    }

    switch (response?.data?.code) {
      case 400:
        snackbar(EMAIL_PASSWORD_REQUIRED, ERROR);
        break;
      case 414:
        snackbar(USER_IS_BANNED_MESSAGE, ERROR);
        break;
      case 410:
        snackbar(PASSWORD_INCORRECT, ERROR);
        break;
      case 404:
        snackbar(USER_NOT_EXITS, ERROR);
        break;
      case 412:
        snackbar(USER_NOT_VERIFIED_MESSAGE, ERROR);
        navigate(PATH_AUTH.verify);
        break;
      case 200:
        setSession(resData.accessToken);
        dispatch({ type: 'LOGIN', payload: { user: resData.user } });
        navigate(PATH_DASHBOARD.account);
        break;
      default:
        break;
    }
  }, []);

  

  // REGISTER
  const register = useCallback(async (registerData, navigate, Snackbar, reset) => {
    const response = await axios.post('/auth/signup', registerData);

    switch (response.data.code) {
      case 400:
        Snackbar(VALIDATION_ERROR_MESSAGE, ERROR);
        reset(false);
        break;
      case 407:
        Snackbar(EMAIL_ALREADY_EXIST, ERROR);
        reset(false);
        break;
      case 201:
        localStorage.setItem('registerEmail', registerData.email);
        if (registerData.role === UserRoles.shopper) {
          Snackbar(USER_REGISTER, SUCCESS_MESSAGE);
        } else {
          reset(false);
          Snackbar(CONFIRM_PAYMENT_USER_EXIST, SUCCESS_MESSAGE);
        }
        navigate(PATH_AUTH.verify);
        break;
      default:
        break;
    }
  }, []);

  const resetPassword = useCallback(async (data, navigate, snackbar, reset) => {
    const response = await axios.post('/auth/resetPassword', data);
    const res = response.data;
    switch (res.code) {
      case 200:
        localStorage.removeItem('email-recovery');
        snackbar(PASSWORD_CHANGED, SUCCESS_MESSAGE);
        setSession(res.data.accessToken);
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: { user: res.data.user } });
        navigate(PATH_DASHBOARD.account);
        break;
      case 404:
        snackbar(EMAIL_NO_EXIST, ERROR);
        reset();
        break;
      case 400:
        snackbar(CONFIRM_PASSWORD_INCORRECT, ERROR);
        reset();
        break;
      case 413:
        snackbar(CODE_INCORRECT, ERROR);
        reset();
        break;
      default:
        break;
    }
  }, []);

  const forgotPassword = useCallback(async (email, navigate, snackbar, reset) => {
    const response = await axios.post('/auth/forgotPassword', { email });
    switch (response.data.code) {
      case 200:
        snackbar(VERIFICATION_SENT, SUCCESS_MESSAGE);
        navigate(PATH_AUTH.resetPasswordVerify);
        break;
      case 404:
        snackbar(EMAIL_NO_EXIST, ERROR);
        reset();
        break;
      default:
        break;
    }
  }, []);

  const verify = useCallback(async (verifyType, data, navigate, snackbar) => {
    const response = await axios.post('/auth/verify', data);
    const { user, accessToken } = response.data.data;
    switch (response.data.code) {
      case 413:
        snackbar('the code is incorrect.', 'error');
        break;
      case 200:
        if (verifyType === VERIFY_TYPE.SIGN_UP_VERIFY) {
          dispatch({ type: 'LOGIN', payload: { user } });
          setSession(accessToken);
        }
        snackbar('verification successful.', 'success');
        if (data?.emailType !== undefined) {
          if (user.role === UserRoles.shopper) {
            navigate(PATH_HOME.download);
          } else if (user.onboardingPass) {
            navigate(PATH_DASHBOARD.account);
          } else {
            navigate(PATH_ONBOARDING.welcome);
          }
        } else {
          navigate(PATH_AUTH.newPassword);
        }
        break;
      default:
        break;
    }
  }, []);

  const resend = useCallback(async (snackbar) => {
    const response = await axios.post('/auth/resendCode', {
      email: localStorage.getItem('registerEmail'),
    });
    switch (response.data.code) {
      case 404:
        snackbar(USER_NOT_EXITS, ERROR);
        break;
      case 200:
        snackbar(VERIFICATION_RESENT, SUCCESS_MESSAGE);
        break;
      default:
        break;
    }
  }, []);

  const createPassword = useCallback(async (data, navigate, snackbar, reset) => {
    const response = await axios.post('/auth/createPassword', data);
    const res = response.data;
    switch (res.code) {
      case 200:
        navigate(PATH_AUTH.login);
        break;
      case 404:
        snackbar(EMAIL_NO_EXIST, ERROR);
        reset();
        break;
      default:
        break;
    }
  }, []);

  const reloadCurrentUser = useCallback(async (userId) => {
    try {
      const response = await axios.get(`/auth/initialize/${userId}`);
      const user = response.data.data;
      dispatch({
        type: 'RELOADME',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // Add event listener for unauthorized access
  useEffect(() => {
    const handleUnauthorized = () => {
      logout();
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, [logout]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      initialize,
      reloadCurrentUser,
      login,
      register,
      resetPassword,
      logout,
      forgotPassword,
      createPassword,
      verify,
      resend,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      initialize,
      reloadCurrentUser,
      login,
      logout,
      register,
      resetPassword,
      forgotPassword,
      createPassword,
      verify,
      resend,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
