/* eslint-disable */
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import confetti from 'canvas-confetti';

interface ConfettiButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  options?: confetti.Options; // Use the type from the confetti library for options
}

export const ConfettiButton: React.FC<ConfettiButtonProps> = ({
  children,
  className,
  options = {},
  ...props
}) => {
  const handleClick = (e: any) => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      angle: Math.random() * 360,
      startVelocity: 60,
      scalar: 1.2,
      ...options
    });

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <button
      className={className}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
};
