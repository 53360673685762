/* eslint-disable */
import {  Stack, Typography, Link } from '@mui/material';
import { ChevronRightIcon } from 'lucide-react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaYoutube, FaTiktok, FaPinterestP, FaSnapchatGhost } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { AppStoreButton, GoogleStoreButton } from '../BoxRevealDemo';
import './index.scss';
import useResponsive from '../../../hooks/useResponsive';
interface Icon {
  icon: JSX.Element;
  url: string;
}

const icons: Icon[] = [
  { icon: <FaInstagram />, url: 'https://instagram.com/realityfence?igshid=OGQ5ZDc2ODk2ZA==' },
  { icon: <FaLinkedinIn />, url: 'https://www.linkedin.com/company/realityfence/' },
  { icon: <FaTwitter />, url: 'https://twitter.com/realityfence' },
  {
    icon: <FaFacebookF />,
    url: 'https://www.facebook.com/profile.php?id=100089795147096&mibextid=LQQJ4d',
  },
  { icon: <FaYoutube />, url: 'https://youtube.com/@realityfence' },
  { icon: <FaTiktok />, url: 'https://www.tiktok.com/@realityfence?_t=8eEN01ILXig&_r=1' },
  { icon: <FaPinterestP />, url: 'https://www.pinterest.com/realityfence/' },
  { icon: <FaSnapchatGhost />, url: 'https://www.snapchat.com/add/realityfence' },
];

type FooterLink = { id: number; title: string; url: string };

export const LINKS = [
  {
    headline: 'Company',
    children: [
      { id: 1, title: 'About', url: '/aboutus' },
      { id: 2, title: 'Contact', url: '/contactus' },
      { id: 3, title: 'Blog', url: '/blog' },
      { id: 4, title: 'Story', url: '/aboutus' },
    ],
  },
  {
    headline: 'Support',
    children: [
      { id: 6, title: 'Blog', url: '#' },
      { id: 7, title: 'RealityFence EDU', url: '#' },
      { id: 8, title: 'FAQs', url: '#' },
      { id: 9, title: 'AR', url: '#' },
    ],
  },
];

export function Footer() {
  const isDesktop = useResponsive('up', '900');
  const isMobile = useResponsive('down', 'sm');
  return (
    <footer
      className="px-7 md:px-10"
      style={{
        backgroundColor: '#1FA9FF',
      }}
    >
      <div className="md:container flex flex-col py-10 md:flex-row md:items-center md:justify-between sm:gap-y-4 gap-y-6">
        <div className="flex flex-col items-start justify-start gap-y-5">
          <a href="/">
            <img
              className="w-[247px] h-[104px]"
              src={
                isDesktop
                  ? '/assets/images/home/RF-Logo-White.svg'
                  : '/assets/images/home/RF Logo White.png'
              }
              alt=""
            />
          </a>
          <Typography className="flex tracking-tight text-slate-100 dark:text-slate-100 sm:text-center gap-1" component="div" color="#ffffff" sx={{ fontSize: '16px', cursor: 'pointer' }}>
            <span style={{fontSize:'xx-large',marginTop:'-7px'}}> ® </span>2024 RealityFence. All rights reserved.
          </Typography>
          {/* {isMobile && (
            <Typography style={{marginTop:'-36px'}} className="flex tracking-tight text-slate-100 dark:text-slate-100" component="div" color="#ffffff" sx={{ fontSize: '16px', cursor: 'pointer' }}>
            reserved.
          </Typography>
          )} */}
          <a className="tracking-tight text-slate-100 dark:text-slate-100 sm:text-center" href="https://dev-server.realityfence.com/static/License_Agreement.pdf">
             Terms and Conditions
          </a>
        </div>
        <div>
          <Stack alignItems="start" gap={{ md: '18px', xs: '9px' }}>
            <Typography component="div" color="#ffffff" fontSize="21px" fontWeight="700">
              Company
            </Typography>
            <a href="/aboutus" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>About</Typography>
            </a>
            <a href="/contactus" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>Contact</Typography>
            </a>
            <a href="/aboutus" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>Story</Typography>
            </a>
          </Stack>
        </div>
        <div>
          <Stack alignItems="start" gap={{ md: '18px', xs: '9px' }}>
            <Typography component="div" color="#ffffff" fontSize="21px" fontWeight="700">
              Support
            </Typography>
            <a href="/blog" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>Blog</Typography>
            </a>
            <a href="/edfence" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>RealityFence EDU</Typography>
            </a>
            <a href="/faqs" style={{ color: '#ffffff' }}>
              <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>FAQs</Typography>
            </a>
          </Stack>
        </div>
        <div>
          <ul className="flex flex-col gap-y-2">
            <Stack spacing="28px" alignItems={{ lg: 'flex-start', placeItems: 'start' }}>
              <Typography
                component="div"
                color="#ffffff"
                fontSize="21px"
                fontWeight="700"
                // paddingTop={{ md: '20px', xs: '45px' }}
              >
                Contact
              </Typography>
              <a
                href="https://www.google.com/maps/search/?api=1&query=6689+Orchard+Lake+Rd+#245,+West+Bloomfield,+MI,+48322"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                <Typography textAlign="start" sx={{ marginRight: '-20px', color: '#FFF' }}>
                  Extend Your Reality LLC
                  <br /> 6689 Orchard Lake Rd #245
                  <br /> West Bloomfield MI, 48322
                  <br /> United States of America
                </Typography>
              </a>

              <Stack>
                <Typography textAlign="start" sx={{ marginRight: '-20px', color: '#ffffff' }}>
                  <a href="tel:2489857575">+1 248-453-1324</a>
                </Typography>
                <Typography textAlign="start" sx={{ marginRight: '-20px', color: '#ffffff' }}>
                  <a href="mailto:contact@realityfence.com">contact@realityfence.com</a>
                </Typography>
              </Stack>
            </Stack>
          </ul>
        </div>
      </div>
      <div className="md:container flex flex-col justify-between gap-y-10 border-t border-dashed py-5 md:flex-row md:items-center">
        <div>
          <div className="flex items-center gap-x-4 pb-4">
            {icons.map((icon, index) => (
              <a
                key={index}
                href={icon.url}
                className="text-xl text-slate-100 hover:text-slate-100 hover:dark:text-white"
              >
                {icon.icon}
              </a>
            ))}
          </div>
          <div className="download-buttons flex items-center">
            <AppStoreButton />
            <GoogleStoreButton />
          </div>
        </div>
      </div>
    </footer>
  );
}
