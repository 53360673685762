/* eslint-disable */
import { Divider, Stack } from '@mui/material';
import React from 'react';
import { AppStoreButton, GoogleStoreButton } from '../BoxRevealDemo';
export function DownloadFile() {
  return (
    <section className="w-full bg-gray-50 dark:bg-gray-900 py-20 md:py-32 lg:py-40">
      <div className="container px-4 md:px-6 mx-auto">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold tracking-tighter mb-8">
            Download RealityFence Now!
          </h2>
          <p className="text-xl md:text-2xl lg:text-3xl text-gray-600 dark:text-gray-300">
            Try Fun-Mode now. It's...Fun.
          </p>
          <Stack
            alignItems="center"
            sx={{
              py: 5,
              borderRadius: 2,
              px: { xs: 3, md: 5 },
            }}
          >
            <Stack direction={{ sm: 'row' }} spacing={{ xs: 2, sm: 5, md: 1 }}>
              <Stack direction="column" alignItems="center">
                <AppStoreButton />
              </Stack>

              <Stack direction="column" sx={{mt:'0px !important'}} alignItems="center">
                <GoogleStoreButton  />
              </Stack>
            </Stack>
          </Stack>
          {/* <div className="flex items-center gap-x-6">
            <img
              className="h-12 md:h-16 cursor-pointer rounded-md border border-neutral-900 dark:border-neutral-700"
              src="https://cdn.magicui.design/playstore-download.png"
              alt="playstore"
            />
            <img
              className="h-12 md:h-16 cursor-pointer rounded-md border border-neutral-900 dark:border-neutral-700"
              src="https://cdn.magicui.design/ios-download.png"
              alt="ios"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default DownloadFile;