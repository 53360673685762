/* eslint-disable */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { m, MotionConfig } from "framer-motion";
// @mui
import { List, Drawer, IconButton, Button, Stack, Grid } from '@mui/material';
// config
import { NAV } from '../../../../config-global';
// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
//
import NavList from './NavList';
//
import { PATH_AUTH, PATH_HOME } from '../../../../routes/paths';
// icons
import NAVICON from '../../../../assets/images/home/Icon/nav_icon.png';
// ----------------------------------------------------------------------
NavMobile.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
};

export default function NavMobile({ isOffset, data ,isDesktop }) {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
    setActive((prev) => !prev)
  };

  const handleClose = () => {
    setOpen(false);
    setActive((prev) => !prev)
  };
  const [active, setActive] = useState(false);

  return (
    <>
         <MotionConfig transition={{ duration: 0.3, ease: "easeInOut" }}>
          <m.button
            onClick={handleOpen}
            animate={active ? "open" : "close"}
            className="relative show-flex-before-900 h-8 w-8 items-center justify-center rounded-md"
          >
            <m.span
              style={{ left: "50%", top: "35%", x: "-50%", y: "-50%" }}
              className="absolute h-0.5 w-6 custom-color-primary dark:bg-white"
              variants={{
                open: {
                  rotate: ["0deg", "0deg", "45deg"],
                  top: ["35%", "50%", "50%"],
                },
                close: {
                  rotate: ["45deg", "0deg", "0deg"],
                  top: ["50%", "50%", "35%"],
                },
              }}
              transition={{ duration: 0.3 }}
            ></m.span>
            <m.span
              style={{ left: "50%", top: "50%", x: "-50%", y: "-50%" }}
              className="absolute h-0.5 w-6 custom-color-primary  dark:bg-white"
              variants={{
                open: {
                  opacity: 0,
                },
                close: {
                  opacity: 1,
                },
              }}
            ></m.span>
            <m.span
              style={{ left: "50%", bottom: "30%", x: "-50%", y: "-50%" }}
              className="absolute h-0.5 w-6 custom-color-primary  dark:bg-white"
              variants={{
                open: {
                  rotate: ["0deg", "0deg", "-45deg"],
                  top: ["65%", "50%", "50%"],
                },
                close: {
                  rotate: ["-45deg", "0deg", "0deg"],
                  top: ["50%", "50%", "65%"],
                },
              }}
              transition={{ duration: 0.3 }}
            ></m.span>
          </m.button>
        </MotionConfig>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Stack justifyContent="center" sx={{ mb: '28px', px: '12px' }}>
            <Grid item sx={{ alignSelf: { xs: 'end', md: 'end' } }}>
              <IconButton color="inherit" edge="start" onClick={handleClose}>
                <Iconify icon="carbon:close-filled" style={{ color: '1FA9FF' }} />
              </IconButton>
            </Grid>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ mt: '4px', mb: '28px' }}
            >
              <Button
                variant="text"
                color="inherit"
                style={{
                  marginTop: '16px',
                  color: 'white',
                  backgroundColor: '#1FA9FF',
                  padding: '8px 16px',
                  fontSize: 12,
                  borderRadius: 999,
                }}
                href={PATH_HOME.subscription}
              >
                Buy Now
              </Button>
              <Button
                variant="text"
                color="inherit"
                style={{
                  marginLeft: '16px',
                  marginTop: '16px',
                  color: 'white',
                  backgroundColor: '#1FA9FF',
                  padding: '8px 23px',
                  fontSize: 12,
                  borderRadius: 999,
                }}
                href={PATH_AUTH.login}
              >
                Login
              </Button>
            </Stack>

            <List component="nav" disablePadding>
              {data.map((link) => (
                <NavList key={link.title} item={link} />
              ))}
            </List>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
