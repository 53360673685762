/* eslint-disable */
import React, { useRef } from "react";
import { m, useScroll, useTransform } from "framer-motion";
import { cn } from "../../../lib/utils";
// import anyfencephone from '../anyfencephone.png';
// import step2 from '../step2.gif';
// import pic1 from '../pic1.png';
// import pic2 from '../pic2.png';
// import autogif from '../autogif.gif';

// Define a type for the props of AnimatedGradientText
interface AnimatedGradientTextProps {
  children: React.ReactNode;
  className?: string;
}

const AnimatedGradientText: React.FC<AnimatedGradientTextProps> = ({ children, className }) => {
  return (
    <m.div
      className={cn(
        "relative mx-auto flex max-w-fit flex-row items-center justify-center rounded-2xl px-4 py-1.5 text-sm font-medium",
        className
      )}
      initial={{ backgroundPosition: "0% 50%" }}
      animate={{ backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"] }}
      transition={{ duration: 5, repeat: Infinity, ease: "linear" }}
      style={{
        backgroundSize: "300% 300%",
        backgroundImage: "linear-gradient(to right, #ff4d4d, #ff9933, #ff4d4d)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
      }}
    >
      {children}
    </m.div>
  );
};

interface CardData {
  id: number;
  title: string;
  content: string;
  image: string;
}

const cardData: CardData[] = [
  {
    id: 1,
    title: "We show up\ntogether",
    content: "Your secret weapon is ready—\npick a fence and watch\nthe curiosity begin.",
    image: 'assets/images/home/easilySell2/anyfencephone.png',
  },
  {
    id: 2,
    title: "Instant Visualization",
    content: "See your fence come to life\nin real-time, right on\nyour property.",
    image: 'assets/images/home/easilySell2/step2.gif', 
  },
  // ... (other card data remains the same)
];

interface FeatureSectionProps {
  title: string;
  content: string;
  image: string;
  index: number;
  isReversed: boolean;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({ title, content, image, index, isReversed }) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [0.95, 1, 1, 0.95]);
  const y = useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [30, 0, 0, 30]);

  return (
    <section ref={sectionRef} className="py-8">
      <div className="container mx-auto px-2 sm:px-4">
        <div className={`flex flex-row items-center justify-center ${isReversed ? 'flex-row-reverse' : ''}`}>
          <m.div 
            className={`w-[50%] sm:w-1/3 ${isReversed ? 'sm:pr-8 pr-0' : 'sm:pl-8 pl-0'} space-y-4`}
            style={{  scale, y }}
          >
            <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold whitespace-pre-line">
              {title}
            </h2>
            <p className="text-md md:text-2xl whitespace-pre-line">
              {content}
            </p>
          </m.div>
          <m.div 
            className="w-[50%] sm:w-1/3 flex justify-center"
            style={{ scale, y }}
          >
            <img

              src={image} 
              alt={title} 
              style={{
                // width: image === 'assets/images/home/easilySell2/autogif.gif' ? '180%' : (image === 'assets/images/home/easilySell2/step2.gif' ? '130%' : '70%'),
              
                maxWidth: 'none'
              }}
              className={` ${image === 'assets/images/home/easilySell2/autogif.gif'  ? 'h-auto w-[100%] sm:w-[180%]': image === 'assets/images/home/easilySell2/autogif-2.gif'  ? 'h-auto w-[100%] sm:w-[180%]' : image === 'assets/images/home/easilySell2/step2.gif' ? 'h-auto w-[100%] sm:w-[130%]' : image === 'assets/images/home/easilySell2/step2-2.gif'  ? 'h-auto w-[100%] sm:w-[130%]': 'h-auto w-[100%] sm:w-[70%]'}`}
            />
          </m.div>
        </div>
      </div>
    </section>
  );
};

const FireDivider: React.FC = () => {
  return (
    <div className="fire-container flex items-center justify-center py-2">
      <div className="border-t border-gray-300 dark:border-gray-700 flex-grow"></div>
      <div className="text-2xl px-2">🔥🔥🔥</div>
      <div className="border-t border-gray-300 dark:border-gray-700 flex-grow"></div>
    </div>
  );
};

export const Features2: React.FC = () => {
  return (
    <div className="relative bg-gray-50 dark:bg-gray-900">
      <div className="relative z-10">
        <div className="container mx-auto px-2 pt-8 pb-4">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-4 text-black dark:text-white">
            So Here's the Story...
          </h2>
          <AnimatedGradientText className="text-xl md:text-2xl lg:text-3xl text-center mb-4 max-w-3xl mx-auto">
            With RealityFence, every estimate becomes boiling hot. Watch out. Seriously.
          </AnimatedGradientText>
          
          <FireDivider />
        </div>
        
        <FeatureSection
          title="We show up together."
          content="Your secret weapon is ready—pick a fence and watch the curiosity begin."
          image='assets/images/home/easilySell2/anyfencephone.png'
          index={0}
          isReversed={false}
        />
        <div className="sm:block hidden">
        <FeatureSection
          
          title="We put on a spectacular presentation."
          content="Right from the start, you've made the best impression, way beyond what they expected for today's estimate."
          image='assets/images/home/easilySell2/step2-2.gif'
          index={1}
          isReversed={true}
        />
        </div>
        <div className="sm:hidden block">
        <FeatureSection
          
          title="We put on a spectacular presentation."
          content="Right from the start, you've made the best impression, way beyond what they expected for today's estimate."
          image='assets/images/home/easilySell2/step2.gif'
          index={1}
          isReversed={true}
        />
        </div>
        

        <div className="container mx-auto px-4 pb-0 pt-10 -mb-20 sm:-mb-0 sm:pt-0 sm:pb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-1 text-black dark:text-white">
            Send them pictures and videos
          </h2>
          <p className="text-xl md:text-2xl text-center mb-1 text-gray-600 dark:text-gray-300">
            Notice your personalized business card right on the captured content
          </p>
          <div className="flex flex-col items-center relative">
            <img src='assets/images/home/easilySell2/pic1.png' alt="Picture 1" className="w-full sm:w-2/3 h-auto -mt-0 sm:-mt-20" />
            <img src='assets/images/home/easilySell2/pic2.png' alt="Picture 2" className="w-[70%] sm:w-1/2 h-auto absolute top-1/4 mt-4 sm:-mt-10" />
          </div>
        </div>


        <div className="sm:block hidden mt-10">
        <FeatureSection
            title="Lastly, save the AutoSketch."
            content="The AutoSketch features a quick sketch, with full linear feet measurements, also broken down line by line. The time saved..."
            image='assets/images/home/easilySell2/autogif-2.gif'
            index={3}
            isReversed={false}
        />
        </div>
        <div className="sm:hidden block mt-10">
        <FeatureSection
            title="Lastly, save the AutoSketch."
            content="The AutoSketch features a quick sketch, with full linear feet measurements, also broken down line by line. The time saved..."
            image='assets/images/home/easilySell2/autogif.gif'
            index={3}
            isReversed={false}
        />
        </div>
      </div>
    </div>
  );
}

export default Features2;
