import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const initialState = {
  isloading: false,
  error: null,
  subscription: null,
  originSubscription: null,
  subscriptions: [],
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    getSubscriptionsSuccess(state, action) {
      state.isloading = false;
      state.subscriptions = action.payload;
    },

    getSubscriptionSuccess(state, action) {
      state.isloading = false;
      state.subscription = action.payload;
    },
    getOriginSubscriptionSuccess(state, action) {
      state.isloading = false;
      state.originSubscription = action.payload;
    },
  },
});

export default slice.reducer;

export const { getSubscriptionsSuccess, getSubscriptionSuccess, getOriginSubscriptionSuccess } =
  slice.actions;

export const getSubscriptions  = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get('/subscription');
    const subscriptionList = response.data.data;
    if (subscriptionList && subscriptionList.length > 0) {
      subscriptionList.sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));
    }
    dispatch(slice.actions.getSubscriptionsSuccess(subscriptionList));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getSubscription = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/subscription/${id}`);
    dispatch(slice.actions.getSubscriptionSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getOnbaording = async () => {
  try {
    const response = await axios.get(`/subscription/onboarding`);
    return response.data;
  } catch (error) {
    console.log("error : ", error);
  }
  return null;
};

export const getOriginSubscription = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/subscription/${id}`);
    dispatch(slice.actions.getOriginSubscriptionSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const addSubscription = (subscriptionData, Alert) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post('/subscription', subscriptionData);
    Alert();
    dispatch(slice.actions.getOriginSubscriptionSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const updateSubscription =
  (id, subscriptionData, SnackBar, navigate) => async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`subscription/${id}`, subscriptionData);
      dispatch(slice.actions.getOriginSubscriptionSuccess(response.data.data));
      navigate(PATH_DASHBOARD.general.subscription.list);
      SnackBar('Successfully updated!', 'success');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

export const deleteSubscription = (id, Snackbar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`subscription/delete/${id}`);
    dispatch(getSubscriptions());
    Snackbar('Succesfully deleted!', 'success');
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
