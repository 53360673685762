/* eslint-disable */
import React, { ReactNode } from 'react';
import { m } from 'framer-motion';

interface MagicCardProps {
  children: ReactNode;
  className?: string;
  image: string;
  zoomedOut?: boolean;
}

const MagicCard: React.FC<MagicCardProps> = ({ children, className, image, zoomedOut = false }) => (
  <m.div 
    className={`flex flex-col ${className} bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-unset sm:overflow-hidden `}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="w-full md:h-3/4 overflow-unset sm:overflow-hidden">
      <img 
        src={image} 
        alt="Comparison" 
        className={`w-full md:h-full h-[300px] ${zoomedOut ? 'object-contain scale-90' : 'object-contain'}`}
      />
    </div>
    <div className="flex-1 flex flex-col items-center justify-start pt-4">
      <div className="text-xl md:text-3xl lg:text-4xl font-bold pb-4">{children}</div>
    </div>
  </m.div>
);

export const Comparison: React.FC = () => {
  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-20 ">
      <div className="container mx-auto px-4 ">
        <h2 className="text-4xl font-bold text-center mb-4  sm:block hidden">
          War means that multiple people
          <br />
          want the same outcome.
        </h2>
        <h2 className="text-4xl font-bold text-center mb-4 block sm:hidden">
          War means that multiple people want the same outcome.
        </h2>
        <p className="text-xl text-center mb-20 md:mb-12 text-gray-600 dark:text-gray-300 sm:block hidden">
          The average fence shopper gets four quotes.
          <br />
          Don't let yourself lose because you did not have
          <br />
          the right tool to get the job done...
        </p>
        <p className="text-xl text-center mb-12 text-gray-600 dark:text-gray-300 block sm:hidden">
          The average fence shopper gets four quotes. Don't let yourself lose because you didn’t have the right tool to get the job done...
        </p>
        <div className="flex h-[600px] w-full flex-col gap-4 md:h-[600px] md:flex-row justify-center items-center mt-28 sm:mt-0">
          <MagicCard 
            className="w-full md:w-1/2 h-full" 
            image="assets/images/home/thing1.png" 
            zoomedOut={true}
          >
            Three of the same quotes
          </MagicCard>
          <MagicCard 
            className="w-full md:w-1/2 h-full" 
            image="assets/images/home/thing2.png"
          >
            <p className='sm:-mt-0 -mt-14'>RealityFence Winner.</p>
          </MagicCard>
        </div>
      </div>
    </section>
  );
};

export default Comparison;
