import { useRef } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { Header } from '../../components/magic-ui-pro/Header';
import { Footer } from '../../components/magic-ui-pro/Footer';
import { StickyHeader } from '../../components/magic-ui-pro/StickyHeader';

// ----------------------------------------------------------------------

export default function HomeLayout() {
  const { pathname } = useLocation();
  const containerRef = useRef(null);

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }} >
      <main
      ref={containerRef}
      className="h-[100vh] w-full overflow-y-auto"
      style={{overflowY:'auto'}}
    >
      <StickyHeader containerRef={containerRef} />
      <div className="w-full">
      <Outlet/>
      </div>
      <Footer />
    </main>
    </Box>
  );
}
