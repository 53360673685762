/* eslint-disable */
import React from 'react';
import { domAnimation, LazyMotion} from 'framer-motion';
import { Hero } from '../../magic-ui-pro/Hero';
import { TextRevealDemo } from '../../magic-ui-pro/TextRevealDemo';
import TextReveal from '../../magicui/text-reveal';
import { SocialProofTestimonials } from '../../magic-ui-pro/SocialProofTestinomials';
import { FeatureScroll1 } from '../../magic-ui-pro/EasilySell';
import { Features2 } from '../../magic-ui-pro/EasilySell2';
import {Stats} from "../../magic-ui-pro/Stats"
import { FeatureSection2 } from '../../magic-ui-pro/FeatureSection2';
// import { SocialProofTestimonials2 } from '../../magic-ui-pro/SocialProofTestinomials2';
import { WordPullUpDemo } from '../../magic-ui-pro/WordPullUpDemo';
import { FeatureCard4 } from '../../magic-ui-pro/FeatureCard4';
import { Stunning , BusinessPotential} from '../../../pages/home/sections';
import { OurExperience } from '../../magic-ui-pro/OurExperience';
import { MagicCardDemo } from '../../magic-ui-pro/MagicCardDemo';
import { Pricing } from '../../magic-ui-pro/NewPricing';
import { StatsSection } from '../../magic-ui-pro/StatsSection';
import { FaqSection } from '../../magic-ui-pro/FaqSection';
import { Header1 } from '../../magic-ui-pro/StickyHeader';
import {AnimatedGridPatternDemo} from '../../magic-ui-pro/CrouselSection'
import {BoxRevealDemo} from '../../magic-ui-pro/BoxRevealDemo'
import {CallToAction} from '../../magic-ui-pro/CallToActionNew'
import {Videos} from '../../magic-ui-pro/Vedios'
import {Comparison} from '../../magic-ui-pro/Comparison'
import {DownloadFile} from '../../magic-ui-pro/DownloadRealityFence'
export default function Home() {
  
  return (<LazyMotion features={domAnimation}>
    <Hero />
    {/* <TextRevealDemo /> */}
    <SocialProofTestimonials />
    {/* <FeatureSection1 /> */}
    {/* <FeatureSection2 /> */}
    <FeatureScroll1/>
    <Stats/>
    <Features2/>
    <Videos />
    <FaqSection />
    <Comparison/>
    {/* <SocialProofTestimonials2 /> */}
    {/* <FeatureCard4 /> */}
    {/* <Stunning /> */}
    {/* <AnimatedGridPatternDemo /> */}
    {/* <OurExperience /> */}
    {/* <CallToAction /> */}
    {/* <BoxRevealDemo/> */}
    {/* <MagicCardDemo/> */}
    <Pricing />
    <CallToAction />
    <DownloadFile />
    {/* <BusinessPotential /> */}
    {/* <StatsSection /> */}
  </LazyMotion>);
}
