/* eslint-disable */
import React from 'react';
import { cn } from "../../../lib/utils";
import { ConfettiButton } from "../../magicui/confetti";

const reviews = [
  { img: "assets/images/home/CustomerTestimonialsNew/test1.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test2.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test3.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test4.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test5.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test6.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test7.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test8.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test9.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test10.png"},
  { img: "assets/images/home/CustomerTestimonialsNew/test11.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test12.png" },
  { img: "assets/images/home/CustomerTestimonialsNew/test13.png"},
  { img: "assets/images/home/CustomerTestimonialsNew/test14.png" },
];
interface ReviewCardProps {
  img: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ img }) => {
  return (
    <ConfettiButton
      className={cn(
        "relative w-96 h-40 cursor-pointer overflow-hidden rounded-xl border mx-2",
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
        "transition-all duration-300 ease-in-out hover:scale-105 hover:z-10 hover:shadow-xl",
        "hover:border-blue-500 dark:hover:border-blue-400",
        "hover:glow-effect" // Add glow effect on hover
      )}
      options={{
        angle: Math.random() * 360,
        spread: 90,
        startVelocity: 60,
        elementCount: 70,
        decay: 0.9
      } as any}
    >
      <div className="w-full h-full p-3">
        <img src={img} alt="Testimonial" className="w-full h-full object-contain" />
      </div>
    </ConfettiButton>
  );
};

export function SocialProofTestimonials() {
  const firstRow = reviews.slice(0, 7);
  const secondRow = reviews.slice(7);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white md:block hidden">
          Listen here, Pal. It’s unbelievable,
          <br />
            but don’t just take our word for it.
        </h2>
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white md:hidden block">
        Listen here, Pal. It’s unbelievable, but don’t just take our word for it.
        </h2>
        <div className="relative w-full overflow-hidden">
          <div className="marquee-container">
            <div className="marquee-row" style={{animationDuration: '60s'}}>
              {[...firstRow, ...firstRow].map((review, index) => (
                <ReviewCard key={`${index}-${review.img}`} {...review} />
              ))}
            </div>
          </div>
          <div className="marquee-container marquee-reverse">
            <div className="marquee-row" style={{animationDuration: '60s'}}>
              {[...secondRow, ...secondRow].map((review, index) => (
                <ReviewCard key={`${index}-${review.img}`} {...review} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocialProofTestimonials;
