
/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { Box, Modal, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { NextButton, PrevButton, useCarouselButtons } from "./carousel-button";

const TWEEN_FACTOR_BASE = 0.4;
const MAX_TRANSLATE_Y = 120;

const numberWithinRange = (number: number, min: number, max: number) =>
  Math.min(Math.max(number, min), max);

interface EmblaCarouselProps {
  slides: React.ReactNode[];
  options?: Record<string, any>;
  className?: string;
  autoplay?: boolean;
  autoplayDelay?: number;
  maxTranslateY?: number;
  tweenFactorBase?: number;
}

const EmblaCarousel: React.FC<EmblaCarouselProps> = (props) => {
  const {
    slides,
    options,
    className,
    autoplay = true,
    autoplayDelay = 2000,
    maxTranslateY = MAX_TRANSLATE_Y,
    tweenFactorBase = TWEEN_FACTOR_BASE,
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(
    options,
    autoplay
      ? [Autoplay({ delay: autoplayDelay, stopOnInteraction: false })]
      : []
  );
  const tweenFactor = useRef(0);
  const tweenNodes = useRef<HTMLDivElement[]>([]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = useCarouselButtons(emblaApi);

  const setTweenNodes = useCallback((emblaApi: any) => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode: HTMLElement) => {
      return slideNode.querySelector(".embla__slide__number") as HTMLDivElement;
    });
  }, []);

  const setTweenFactor = useCallback((emblaApi: any) => {
    tweenFactor.current = tweenFactorBase * emblaApi.scrollSnapList().length;
  }, [tweenFactorBase]);

  const tweenTranslate = useCallback(
    (emblaApi: any, eventName?: string) => {
      const engine = emblaApi.internalEngine();
      const scrollProgress = emblaApi.scrollProgress();
      const slidesInView = emblaApi.slidesInView();
      const isScrollEvent = eventName === "scroll";

      emblaApi
        .scrollSnapList()
        .forEach((scrollSnap: number, snapIndex: number) => {
          let diffToTarget = scrollSnap - scrollProgress;
          const slidesInSnap = engine.slideRegistry[snapIndex];

          slidesInSnap.forEach((slideIndex: number) => {
            if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

            if (engine.options.loop) {
              engine.slideLooper.loopPoints.forEach((loopItem: any) => {
                const target = loopItem.target();

                if (slideIndex === loopItem.index && target !== 0) {
                  const sign = Math.sign(target);

                  if (sign === -1) {
                    diffToTarget = scrollSnap - (1 + scrollProgress);
                  }
                  if (sign === 1) {
                    diffToTarget = scrollSnap + (1 - scrollProgress);
                  }
                }
              });
            }

            const tweenValue = Math.abs(diffToTarget * tweenFactor.current);
            const translateY = numberWithinRange(
              tweenValue * maxTranslateY,
              0,
              maxTranslateY
            );

            const opacity = numberWithinRange(1 - tweenValue * 0.5, 0.5, 1);

            const tweenNode = tweenNodes.current[slideIndex];
            if (tweenNode) {
              tweenNode.style.transform = `translateY(${translateY}px)`;
              tweenNode.style.opacity = opacity.toString();
            }
          });
        });
    },
    [maxTranslateY]
  );

  useEffect(() => {
    if (!emblaApi) return;

    setTweenNodes(emblaApi);
    setTweenFactor(emblaApi);
    tweenTranslate(emblaApi);

    emblaApi
      .on("reInit", setTweenNodes)
      .on("reInit", setTweenFactor)
      .on("reInit", tweenTranslate)
      .on("scroll", tweenTranslate);
  }, [emblaApi, setTweenFactor, setTweenNodes, tweenTranslate]);

  return (
    <div className="relative">
      <div className="py-10 overflow-visible" ref={emblaRef}>
        <div className="flex">
          {slides.map((slide, index) => (
            <div
              className="max-[350px]:[flex:0_0_18rem] [flex:0_0_20rem] flex pl-4"
              key={index}
            >
              <div
                className={`embla__slide__number w-full flex items-center justify-center h-full ${className || ""}`}
              >
                <div className="h-full w-full">
                  <div className="group relative z-0 h-full w-full overflow-hidden rounded">
                    <div className="overflow-hidden rounded h-full w-full">
                      {slide}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 py-10">
        <PrevButton
          className="w-10 h-10 bg-neutral-200 dark:bg-neutral-800 flex items-center justify-center hover:ring-1 hover:ring-neutral-300 dark:hover:ring-neutral-700 rounded-full"
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
        />
        <NextButton
          className="w-10 h-10 bg-neutral-200 dark:bg-neutral-800 flex items-center justify-center hover:ring-1 hover:ring-neutral-300 dark:hover:ring-neutral-700 rounded-full"
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
        />
      </div>
    </div>
  );
};

// VideoThumbnail Component
const VideoThumbnail: React.FC<{ imageUrl: string; videoUrl: string }> = ({ imageUrl, videoUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%' }}>
      <img
        src={imageUrl}
        alt="video thumbnail"
        className="w-full h-full rounded"
        onClick={handleOpen}
        style={{ borderRadius: '8px' }}
      />
      <IconButton
        onClick={handleOpen}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
        }}
      >
        <PlayArrowIcon fontSize="large" />
      </IconButton>

      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          component="video"
          controls
          autoPlay
          sx={{
            width: '80%',
            maxHeight: '80%',
            borderRadius: '8px',
          }}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
      </Modal>
    </div>
  );
};

// Sample Videos Component with EmblaCarousel
const youtubeVedios = [
  {
    imageUrl:'assets/images/home/Vedios/1.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video1.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/2.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video2.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/3.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video3.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/4.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video4.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/5.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video5.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/6.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video6.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/7.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video7.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/8.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video8.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/9.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video9.mp4'
  },
  {
    imageUrl:'assets/images/home/Vedios/10.png',
    videoUrl:'https://rf-test-test.s3.amazonaws.com/marketing_page/video_thumbnails/Video10.mp4'
  }

];

export const Videos: React.FC = () => {
  const OPTIONS = { loop: true };
  const slides = youtubeVedios.map((video, index) => (
    <VideoThumbnail key={index} imageUrl={video.imageUrl} videoUrl={video.videoUrl} />
  ));

  return (
    <section className="flex flex-col items-center justify-center w-full">
      <div className="max-w-7xl mx-auto overflow-hidden p-5 pt-10 md:py-20 pb-2 sm:pb-28 flex items-center justify-center w-full">
       
      <EmblaCarousel
          slides={slides}
          options={OPTIONS}
          maxTranslateY={250}
          tweenFactorBase={0.2}
          className="w-full min-h-[25rem] h-full"
        />
        {/* <div className="pointer-events-none absolute inset-y-0 left-0 hidden h-full w-1/5 bg-gradient-to-r from-white dark:from-black md:block"></div>
        <div className="pointer-events-none absolute inset-y-0 right-0 hidden h-full w-1/5 bg-gradient-to-l from-white dark:from-black md:block"></div> */}
      </div>
    </section>
  );
};

export default Videos;
