/* eslint-disable */
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { getQAs } from '../../../redux/slices/qa';
import { Stack, useMediaQuery, CircularProgress } from '@mui/material';

interface FAQInterface {
  question: string;
  answer: string;
  isDeleted: boolean;
}

const FAQItem = ({ question, answer, isDeleted }: FAQInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  if (isDeleted) return null; // Don't render if deleted

  return (
    <div className="border border-gray-200 rounded-xl shadow-sm transition-all hover:border-primary mb-4">
      <button
        className="w-full px-4 py-4 text-left text-base sm:text-lg font-medium focus:outline-none flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="text-sm text-gray-500"
        >
          ▼
        </motion.span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="px-4 pb-4 text-sm sm:text-base" dangerouslySetInnerHTML={{ __html: answer }} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export function FaqSection() {
  const dispatch = useDispatch();
  const { QAs, isLoading } = useSelector((state: any) => state.qa);
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    dispatch(getQAs() as any);
  }, [dispatch]);

  const Loading = () => (
    <Stack width="100%" sx={{ alignItems: 'center', mb: 20, mt: 15 }}>
      <CircularProgress color="primary" />
    </Stack>
  );

  return (
    <section id="faq">
       <div className="container px-4 md:px-6 w-full py-4 md:py-24 lg:py-32">
          <div className="text-center space-y-4 py-6 mx-auto">
            <h4 className="text-[42px] font-medium mb-2 text-balance max-w-3xl mx-auto tracking-tighter">
              Frequently Asked Questions
            </h4>
          </div>
          {isLoading ? (
        <Loading />
      ) : (
        <div className="max-w-3xl mx-auto">
        {QAs.map((category: any) =>
          category.qaData.map((faq: any) => (
            <FAQItem key={faq.id} question={faq.question} answer={faq.answer} isDeleted={faq.isDeleted} />
          ))
        )}
      </div>
      )}
          
        </div>
     
    </section>
  );
}
